import { Controller } from "stimulus";

export default class Header extends Controller {
  static targets = ["menu"]

  toggle() {
    if (this.menuTarget.style.display !== 'block') {
      this.menuTarget.style.display = 'block';
    } else {
      this.menuTarget.style.display = 'none';
    }
  }
}
